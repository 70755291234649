import React, { useEffect } from 'react';
import { HeaderSimple } from '../components/Navbar';
import { CategoriesSection, Products } from '../components/Sections';
import { Footer } from '../components/Sections/Footer';

export function Home () {
    return (
        <>
            <HeaderSimple />
            <CategoriesSection />
            <Products />
            <Footer/>
        </>
    );
}
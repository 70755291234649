import {Group, Title, Text, SimpleGrid, Container, useMantineTheme, Stack, Loader, Image, Button} from '@mantine/core';
import { ProductCard } from '../Cards';
import useAxios from 'axios-hooks'
import { Link, useSearchParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { searchSortedData } from '../../lib/sort';
import { useMediaQuery } from '@mantine/hooks';
import axios from 'axios';
import { ProductsSlider } from './ProductsSlider';
import classes from './../../styles/Products.module.css';
import useStore from '../../store/useStore';

export function Products() {
    const theme = useMantineTheme();
    let [searchParams, setSearchParams] = useSearchParams();
    let [allProducts, setAllProducts] = useState<any>([]);
    let [filterdAllProducts, setFiltredAllProducts] = useState<any>([]);
    const [page, setPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);
    const [{ data: dataAllProducts, loading, error }, refetch] = useAxios(
        `${process.env.REACT_APP_API_URL}/posting-products/?page=${page}&limit=20`
    )
    const matches = useMediaQuery('(max-width: 36em)');
    let [allCategories, setAllCategories] = useState<any[]>([]);
    const dataStore = useStore((state: any) => state.store);

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API_URL}/category`)
        .then(({data}) => {
            setAllCategories(data)
        })
        .catch((error) => console.log(error));
    }, [])

    useEffect(() => {
        if (dataAllProducts && dataAllProducts.data.length >= 0 && filterdAllProducts.length < dataAllProducts.totalPages) {
            setAllProducts([...allProducts, ...dataAllProducts.data])
            setFiltredAllProducts([...filterdAllProducts, ...dataAllProducts.data])
            setTotalPages(dataAllProducts.totalPages)
        }
    }, [dataAllProducts])

    useEffect(() => {
        const search = searchParams.get("search");
        if (search && search !== "") {
            setSearchParams({search})
            let newData = searchSortedData(allProducts, ["name"], search)
            setFiltredAllProducts(newData)
        } else {
            setSearchParams()
            setFiltredAllProducts(allProducts)
        }
    }, [searchParams.get("search")])

    const loadMore = () => {
        setPage(page + 1);
    };
    if (error) return <p>Error!</p>

    return (
        <Container size="xl" mt={50}>
            {searchParams.get("search") && searchParams.get("search") !== ""
                ? <>
                    <Title order={2} className={classes.title} ta="center" mt="sm">نائج البحث عن : {searchParams.get("search")}</Title>
                </>
                : <>
                    <Title 
                        order={2} className={classes.title} ta="center" mt="sm"
                        style={{
                            borderBottom: `6px solid ${dataStore?.information?.backgroundColor || "#48a172"}99`
                        }}
                    >منتجاتنا اصلية وذات جودة عالية</Title>
                    <Text mb={20} c="dimmed" className={classes.description} ta="center" mt="md" fw={"bold"}>تصفح قائمة المنتجات</Text>
                </>
            }
            {loading
                ? <Stack align='center' justify='center' mt={50}>
                    <Loader color={dataStore?.information?.backgroundColor || "#48a172"} size="md" type="bars" />
                </Stack>
                : null
            }

            {searchParams.get("search") && searchParams.get("search") !== ""
                ? null
                : <ProductsSlider data={allProducts} />
            }

            <Group justify='space-between' h={50} my={30} align='center'>
                <Title order={4} ta="right">منتجاتنا</Title>
            </Group>
            {filterdAllProducts && filterdAllProducts?.length > 0
                ? <>
                    <SimpleGrid cols={{ base: 2, sm: 3, md: 4, lg: 5, xl: 5 }} spacing="sm" mt={50}>
                        {filterdAllProducts?.map((item: any, index: number) => (
                            <ProductCard
                                key={index}
                                id={item._id}
                                title={item?.name}
                                image={item?.thumbnail}
                                price={item?.price }
                                priceAfterDiscount={item.priceAfterDiscount}
                                rating={item?.rating}
                            />
                        ))}
                    </SimpleGrid>
                
                    {filterdAllProducts.length < totalPages
                        ? <Group justify='center' align='center' mt={30}>
                            <Button variant='white' color='#444' onClick={loadMore}>عرض المزيد</Button>
                        </Group>
                        : null
                    }
                </>
                : <Stack align='center' justify='center' mt={50}>
                    <Image src={"/shopping-trolley.png"} h={"100px"} w={"100px"} fit='contain' />
                </Stack>
            }
        </Container>
    );
}